import { icon } from '@/tools'
import { Tooltip } from 'antd'

// 问题解释
const ProblemExplanation = (props) => {
	const { iconName = 'QuestionCircleOutlined', text } = props

	return (
		<Tooltip placement='top' title={text}>
			{icon(iconName)}
		</Tooltip>
	)
}
export default ProblemExplanation
