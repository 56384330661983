import { useEffect, useMemo, useState } from 'react'
import Modal from '@/components/modal'
import { useRecoilState } from 'recoil'
import { openApp as _openApp, user as _user } from '@/state'
import { appIds, DOMIds, getReg } from '@/tools'
import { Button, Input, Form, Flex, Spin, Space, message, Select } from 'antd'
import FormItmeRender from '@/components/formItmeRender'
import { cardAdd, userCardAdd, classAll } from '@/api'
import IconOrColor from '@/components/iconOrColor'
import ProblemExplanation from '@/components/problemExplanation'
import styled from 'styled-components'


const Box = styled.div`
  width: 100%;
  height: 0px;
  min-height: 100%;
  background-color: #f0f0f0;
  padding: 20px;
`

const Container = styled.section`
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: auto;
`;

const Add = () => {
  const [form] = Form.useForm()
  const [openApp] = useRecoilState(_openApp) // 打开的应用
  const [user] = useRecoilState(_user)
  const [addConfirmLoading, setAddConfirmLoading] = useState(false) // 加载状态
  const [currentCardData, setCurrentCardData] = useState(null) // 当前(添加的)card数据
  const [isChange, setIsChange] = useState(false) // 列表数据是否变化(当前是否新增成功)
  const [classAllOptions, setClassAllOptions] = useState([]) // 当前用户的全部分类

  // 判断当前用户是否是admin账户
  const isAdmin = useMemo(() => {
    return user?._id === '64c8f0294d5a62d795b918a2'
  }, [user])

  // 偏好设置是否打开状态
  const isOpen = useMemo(() => {
    return openApp?.includes(appIds.add)
  }, [openApp])

  // 关闭窗口时 清空缓存 并根据 isChange 判断是否需要刷新<桌面>列表
  useEffect(() => {
    if (!isOpen) {
      form.resetFields()
      setCurrentCardData(null)
      if (isChange) {
        window?.__app__?.refreshDesktop?.() // 刷新桌面
        setIsChange(false) // 重置刷新状态
      }
    }
  }, [isOpen])

  // 管理员 获取 分类选项
  useEffect(() => {
    if (isAdmin && isOpen) {
      classAll().then(res => {
        setClassAllOptions(
          res?.data?.rows?.map(v => ({
            ...v,
            value:  v._id,
          })) || []
        )
      })
    }
  }, [isAdmin, isOpen])

  // 搜索时 添加卡片
  const onSearch = async (url, event) => {
    // 阻止事件冒泡，防止触发表单提交
    event?.preventDefault();
    const valdData = form.getFieldError('url')

		// 如果连接输入错误 会返回错误arr 否则返回输入内容
		if (Array.isArray(valdData) && valdData.length) {
			return
		} else {
			setAddConfirmLoading(true)

			// 添加卡片 并获取添加结果
			try {
				const res = await cardAdd({ data: { url } })
        const { data } = res

        setCurrentCardData(data)

        const fd = {
          customizeTitle: data.title,
          customizeDescription: data.description,
          customizeIcon: data.appleTouchIcon || data.shortcutIcon || data.icon,
        }

        form.setFieldsValue(fd)
			} catch (error) {
				console.log(error)
			}

			setAddConfirmLoading(false)
		}
  }

  // 提交
  const submit = async (fd) => {
    const { customizeTitle, customizeDescription, customizeIcon, classIds } = fd

    setAddConfirmLoading(true)
    try {
      const req = {
        classIds: classIds?.map(v => [v]) || [], // 将来分类有可能迭代 嵌套的层级关系(所以现在以二维数组保存 为了和级联选择器的数据结构兼容)
				cardId: currentCardData._id,
				sort: 1,

				customizeTitle: customizeTitle === currentCardData.title ? undefined : customizeTitle,
				customizeDescription: customizeDescription === currentCardData.description ? undefined : customizeDescription,
        customizeIcon: (customizeIcon === currentCardData.appleTouchIcon || customizeIcon === currentCardData.shortcutIcon || customizeIcon === currentCardData.icon) ? undefined : customizeIcon,
        type: 2, // 第三方应用
      }
      await userCardAdd({ data: req })
      reset()
      message.success('添加成功')
      setIsChange(true)
    } catch (error) {
      console.error(error)
    }
    setAddConfirmLoading(false)
  }

  // 重置表单状态
  const reset = () => {
    form.resetFields()
    setCurrentCardData(null)
  }

  // 表单项
  const formData = useMemo(() => {
    const formItems = []
    if (onSearch) {
      formItems.push({
        formItemProps: {
          name: 'url',
          label: '网址',
          rules: [{ required: true }, getReg('url')],
        },
        render: <Input.Search onSearch={(value, event) => onSearch(value, event)} placeholder='输入完整网址例:https:/www.xxx.com/' allowClear />,
      })
    }

    // admin 用户需要选择分类 (有分类的卡片才会展示在应用商店)
    if (isAdmin && currentCardData && reset) {
      formItems.push({
        formItemProps: {
          label: "分类",
          name: "classIds",
          rules: [{ required: true, message: '请输入' }]
        },
        render: <Select mode="multiple" allowClear options={classAllOptions} />
      })
    }

    if (currentCardData && reset) {
      formItems.push(
        {
          formItemProps: {
            label: "标题",
            name: "customizeTitle",
            rules: [{ required: true, message: '请输入' }]
          },
          render: <Input allowClear />
        },

        {
          formItemProps: {
            label: (
              <Space>
                <span>图标</span>
                <ProblemExplanation text={'可自定义展示图标或指定颜色'} />
              </Space>
            ),
            name: "customizeIcon",
          },
          render: <IconOrColor />
        },

        {
          formItemProps: {
            label: "描述",
            name: "customizeDescription",
          },
          render: <Input.TextArea allowClear rows={3} />
        },

        {
          render: (
          <Flex justify='center' gap={10}>
            <Button onClick={reset}>重置</Button>
            <Button htmlType='submit' type='primary'>保存</Button>
          </Flex>
          ),
        }
      )
    }

    return formItems
  }, [currentCardData, onSearch, reset])

  return (
    <Modal
      open={isOpen}
      getContainer={`#${DOMIds.main}`}
      title={<h3>添加应用</h3>}
      isMaximized={true}
      appId={appIds.add}
    >
      <Box>
        <Container>
          <Spin spinning={addConfirmLoading}>
            <Form form={form} onFinish={submit}>
              <FormItmeRender data={formData} />
            </Form>
          </Spin>
        </Container>
      </Box>
    </Modal>
  )
}

export default Add