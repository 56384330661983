/*
  删除动效组件
    基于 react / styled-components / @ant-design/icons 封装删除中动效的 DeleteAnimation组件:

    1 使用时用 DeleteAnimation组件 包裹 其他内容 组件可以传入 deleting 和 onClick
        例如: <DeleteAnimation> ... </DeleteAnimation>
    2 deleting 为 true 时 <DeleteAnimation>  </DeleteAnimation> 内部的 children 会颤抖 参考ios 删除app时的动效
    3 deleting 为 true 时 children 的右上角会有 x 的icon  要对icon 添加 点击事件绑定 事件逻辑用使用者传入
    4 处于批量删除状态时如果点击空白处则取消状态
*/

import React, { useEffect, useRef, useCallback } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

// 颤抖动画
const shake = keyframes`
  0% { transform: rotateZ(0deg); }
  25% { transform: rotateZ(-1deg); }
  50% { transform: rotateZ(1deg); }
  75% { transform: rotateZ(-1deg); }
  100% { transform: rotateZ(1deg); }
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
  ${({ deleting }) => deleting && css`
    animation: ${shake} 0.3s infinite;
  `}
`;

const CloseIcon = styled(CloseOutlined)`
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  color: #666;
  background-color: #fff;
  border-radius: 50%;
  padding: 3px;
  border: 1px solid #eee;
  font-size: 6px;

  &:hover {
    color: #5388f8;
    border: 1px solid #5388f8;
  }
`;

const DeleteAnimation = ({ deleting, setDeleting, onClick, children }) => {
  const containerRef = useRef(null);

  // 点击容器时
  const handleOutsideClick = useCallback((e) => {
    // 如果组件处于删除状态，且点击发生在当前组件外部，则取消删除状态
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setDeleting?.(false);
    }
  }, [setDeleting]);

  const handleContainerClick = useCallback((e) => {
    // 处于删除状态时 阻止冒泡 防止误触跳转页面
    if (deleting) {
      e.stopPropagation();
    }
  }, [deleting]);

  // 处于删除状态时点击其他地方取消状态
  useEffect(() => {
    let timeoutId;

    if (deleting) {
      // 使用 setTimeout 确保在所有 click 事件处理完后再添加监听器
      timeoutId = setTimeout(() => {
        document.addEventListener('click', handleOutsideClick);
      }, 0);

      return () => {
        clearTimeout(timeoutId);
        document.removeEventListener('click', handleOutsideClick);
      };
    }
  }, [deleting, handleOutsideClick]);

  // 点击关闭按钮
  const handleCloseClick = useCallback((e) => {
    e.stopPropagation();
    onClick?.(e);
  }, [onClick]);

  return (
    <Container
      deleting={deleting}
      ref={containerRef}
      onClick={handleContainerClick}
    >
      {children}
      {deleting && <CloseIcon onClick={handleCloseClick} />}
    </Container>
  );
};

export default DeleteAnimation;