import { useEffect } from 'react';

const ShieldDragHint = ({ children }) => {
  useEffect(() => {
    const originalConsoleError = console.error;

    console.error = function(...args) {
      // 这个提示信息来自于 第三方库 使用了react 不推荐的语法(过滤掉即可)
      if (!args?.some?.(arg =>
        arg?.includes?.('Support for defaultProps will be removed from memo components')
        // || arg.includes('net::ERR_')
      )) {
        originalConsoleError.apply(console, args);
      }
    }
  }, [])
  return <>{children}</>;
}

export default ShieldDragHint