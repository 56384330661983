import { appIds, modalUp } from '@/tools'


// 处理异常 - 约定的特殊code调用对应的处理逻辑(例: 掉线是跳转登录页面)
const data = {
	10001: {
		desc: 'token错误 请重新登录',
		handle: async () => {
			window?.__app__?.signOut(false) // touken 过期退出登录

			// 打开登录窗口
			const appId = appIds.settings
			const openApp = window?.__app__?.getState('openApp')
			const minimizedApp = window?.__app__?.getState('minimizedApp')
			const modalZIndex = window?.__app__?.getState('modalZIndex')

			if (!openApp.includes(appId)) {
				window?.__app__?.setState('openApp', appId) // 传入要打开的 appId
			}
	
			// 处于最小化状态时 解除最小化
			if (minimizedApp.includes(appId)) {
				const s = new Set(minimizedApp)
				s.delete(appId)
				const val =  [...s]

				window?.__app__?.setState('minimizedApp', val) // 传入要打开的 appId
			}
	
			// 把当前 app z-index 提到最高
			const setModalZIndex = (val) => window?.__app__?.setState('modalZIndex', val)
			modalUp({ modalZIndex, appId, setModalZIndex })
		},
	}

	// ...
}

export default data
