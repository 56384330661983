import React, { memo, useRef, useCallback } from 'react';
import styled from 'styled-components';
import Title from './Title'
import { Switch } from 'antd'; // 假设使用 Ant Design 的开关组件
import { ChevronRight } from 'lucide-react';
import Pic from '@/components/pic'
import { useRecoilState } from 'recoil'
import { classDetailsOpen as _classDetailsOpen } from '@/state'

const ItemContainer = styled.a`
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
  background-color: ${props => props.$active ? '#4f9fff' : 'transparent'};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

const RightText = styled.div`
  color: ${props => props.$active ? '#fff' : '#999'};
  margin-right: 10px;
`;

const SettingsItem = memo(({ id, icon, title, rightText, toggle, activeKey, setActiveKey, isShowArrow = true, isEnableAnchor = false }) => {
  const switchRef = useRef()
  const [, setClassDetailsOpen] = useRecoilState(_classDetailsOpen)

  const style = { marginRight: 10 }

  const handleClick = useCallback((id) => {
    setClassDetailsOpen(false) // 关闭 分类详情
    window?.__app__?.backtrack?.() // 关闭 <轮播图 & 搜索> 详情

    setActiveKey(id);

    if (isEnableAnchor) {
      const targetElement = document.getElementById(id);
      if (targetElement) {
        requestAnimationFrame(() => {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        })
      }
    }
  }, [id, setActiveKey, isEnableAnchor]);

  // 分类选项
  return (
    <ItemContainer
      $active={activeKey === id}
      onClick={() => handleClick(id)}
    >
      {/* 图标 */}
      {
        !icon ? null :
        typeof icon === 'string' ?
          <Pic src={icon} picStyle={style} size={60} /> :
          <div style={style}>{icon}</div>
      }

      {/* 标题 */}
      <Title active={activeKey === id}>{title}</Title>

      {/* 描述 */}
      {rightText && <RightText $active={activeKey === id}>{rightText}</RightText>}

      {/* 开关 */}
      {toggle && <Switch ref={switchRef} />}

      {/* 箭头 */}
      {isShowArrow && <ChevronRight color={ activeKey === id ? '#fff' : '#999' } />}
    </ItemContainer>
  );
});

export default SettingsItem;