import Browser from '@/apps/browser'
import AppStore from '@/apps/appStore'
import Settings from '@/apps/settings'
import Test from '@/apps/test'
import Add from '@/apps/add'

import safari from '@/apps/browser/safari.png'
import appStore from '@/apps/appStore/appStore.png'
import settings from '@/apps/settings/settings.png'
import add from '@/apps/add/add.png'
import test from '@/apps/test/test.png'
import { isProduction } from '@/tools'

export const appData = [
  {
    type: 1,
    "_id": "appStore",
    "appId": "appStore",
    cardId: 'appStore',
    "sort": 1,
    "cardData": {
      "title": "应用商店",
      "icon": appStore,
      "description": "应用商店",
    }
  },
  {
    type: 1,
    "_id": "settings",
    "appId": "settings",
    cardId: 'settings',
    "sort": 1,
    "cardData": {
      "title": "系统设置",
      "icon": settings,
      "description": "系统设置",
    }
  },
  {
    type: 1,
    "_id": "add",
    "appId": "add",
    cardId: 'add',
    "sort": 1,
    "cardData": {
      "title": "添加应用",
      "icon": add,
      "description": "添加应用",
    }
  },
]

// 非生产环境下展示测试icon
if (!isProduction) {
  appData.push(
    // {
    //   type: 1,
    //   "_id": "browser",
    //   "appId": "browser",
    //   "sort": 1,
    //   "cardData": {
    //     "title": "浏览器",
    //     "icon": safari,
    //     "description": "浏览器",
    //   }
    // },
    // {
    //   type: 1,
    //   "_id": "test",
    //   "appId": "test",
    //   "sort": 1,
    //   "cardData": {
    //     "title": "测试应用",
    //     "icon": test,
    //     "description": "测试应用",
    //   }
    // },
    // {
    //   type: 3,
    //   "_id": "people",
    //   "appId": "people",
    //   "sort": 1,
    //   "cardData": {
    //     "title": "people",
    //     "icon": "http://127.0.0.1:777/uploads/microApp/64c8f0294d5a62d795b918a2 2024-10-09 01:14:27.ico",
    //     "description": "people",
    //   }
    // },
    // {
    //   type: 3,
    //   "_id": "后台管理",
    //   "appId": "后台管理",
    //   "sort": 1,
    //   "cardData": {
    //     "title": "后台管理",
    //     "icon": "http://127.0.0.1:777/uploads/microApp/64c8f0294d5a62d795b918a2 2024-10-09 01:16:48.png",
    //     "description": "后台管理",
    //   }
    // },
  )
}

// 文件夹测试数据
export const folderTestData = {
  type: 'folder', // 文件夹
  _id: 'folder-965dbfebe91ecd7859fa793461112', // 文件夹必须要有唯一的 _id
  title: '文件夹',
  data: [
      {
          "_id": "065dbfebe91ecd7859fa793461",
          "cardId": "65dbfebc91ecd7859fa79343",
          "classIds": [
            [
            "65cb51e35a976f6c233eac70"
            ]
          ],
          "sort": 1,
          "createdBy": "admin",
          "updatedBy": "admin",
          "createdById": "64c8f0294d5a62d795b918a2",
          "updatedById": "64c8f0294d5a62d795b918a2",
          "createdAt": 1708916414573,
          "updatedAt": 1715029796236,
          "isDel": false,
          "isShow": true,
          "cardIdObj": "65dbfebc91ecd7859fa79343",
          "cardData": {
              "_id": "65dbfebc91ecd7859fa79343",
              "url": "https://github.com/",
              "title": "GitHub: Let’s build from here · GitHub",
              "icon": "https://github.githubassets.com/favicons/favicon.svg",
              "shortcutIcon": null,
              "appleTouchIcon": null,
              "route-pattern": "/",
              "route-controller": "dashboard",
              "route-action": "index",
              "current-catalog-service-hash": "40dc28bd654b20f337468a532ff456ed5863889cfbb4e982b793597321d48d3f",
              "request-id": "F5B5:16C6:42A3EF:4A24CF:65DBFEBB",
              "html-safe-nonce": "51945a7b90a1355d03a716c9ada304f7ece15897d079623d80776bdc858ded11",
              "visitor-payload": "eyJyZWZlcnJlciI6IiIsInJlcXVlc3RfaWQiOiJGNUI1OjE2QzY6NDJBM0VGOjRBMjRDRjo2NURCRkVCQiIsInZpc2l0b3JfaWQiOiI3MDYwMTI2MTYxMjM1MDgyOTM5IiwicmVnaW9uX2VkZ2UiOiJzb3V0aGVhc3Rhc2lhIiwicmVnaW9uX3JlbmRlciI6InNvdXRoZWFzdGFzaWEifQ==",
              "visitor-hmac": "85e1d46a3056fa45f458ebe7d37b682072837705cd03e7905b67601ff33aecbc",
              "page-subject": "GitHub",
              "github-keyboard-shortcuts": "dashboards,copilot",
              "google-site-verification": "Apib7-x98H0j5cPqHWwSMm6dNU4GmODRoqxLiDzdx9I",
              "octolytics-url": "https://collector.github.com/github/collect",
              "viewport": "width=device-width",
              "description": "GitHub is where over 100 million developers shape the future of software, together. Contribute to the open source community, manage your Git repositories, review code like a pro, track bugs and features, power your CI/CD and DevOps workflows, and secure code before you commit it.",
              "apple-itunes-app": "app-id=1477376905, app-argument=https://github.com/",
              "twitter:image:src": "https://github.githubassets.com/assets/campaign-social-031d6161fa10.png",
              "twitter:site": "@github",
              "twitter:card": "summary_large_image",
              "twitter:title": "GitHub: Let’s build from here",
              "twitter:description": "GitHub is where over 100 million developers shape the future of software, together. Contribute to the open source community, manage your Git repositories, review code like a pro, track bugs and fea...",
              "hostname": "github.com",
              "expected-hostname": "github.com",
              "turbo-cache-control": "no-preview",
              "is_logged_out_page": "true",
              "turbo-body-classes": "logged-out env-production page-responsive header-overlay home-campaign",
              "browser-stats-url": "https://api.github.com/_private/browser/stats",
              "browser-errors-url": "https://api.github.com/_private/browser/errors",
              "theme-color": "#1e2327",
              "createdBy": "admin",
              "updatedBy": "admin",
              "createdById": "64c8f0294d5a62d795b918a2",
              "updatedById": "64c8f0294d5a62d795b918a2",
              "createdAt": 1708916412052,
              "updatedAt": 1708916412052,
              "isDel": false
          }
      },
      {
          "_id": "a65dc467e91ecd7859fa794322",
          "cardId": "65dc467c91ecd7859fa7942f",
          "classIds": [
            [
            "65cb51e35a976f6c233eac70"
            ]
          ],
          "sort": 21,
          "createdBy": "admin",
          "updatedBy": "admin",
          "createdById": "64c8f0294d5a62d795b918a2",
          "updatedById": "64c8f0294d5a62d795b918a2",
          "createdAt": 1708934782896,
          "updatedAt": 1708934782896,
          "isDel": false,
          "isShow": true,
          "cardIdObj": "65dc467c91ecd7859fa7942f",
          "cardData": {
              "_id": "65dc467c91ecd7859fa7942f",
              "url": "https://avuejs.com/",
              "title": "Avue",
              "icon": "https:/avuejs.com/images/logo.png",
              "shortcutIcon": "https:/avuejs.com",
              "appleTouchIcon": null,
              "viewport": "width=device-width,initial-scale=1",
              "generator": "VuePress 2.0.0-beta.49",
              "description": "前端搬砖神器,让数据驱动视图,更加贴合企业开发",
              "createdBy": "admin",
              "updatedBy": "admin",
              "createdById": "64c8f0294d5a62d795b918a2",
              "updatedById": "64c8f0294d5a62d795b918a2",
              "createdAt": 1708934780731,
              "updatedAt": 1710238669383,
              "isDel": false
          }
      },
  ],
}

// docker 测试数据
export const dockerData = [
  {
    type: 'card', // 卡片
    "_id": "docker-65dbfebe91ecd7859fa79346111",
    "cardId": "65dbfebc91ecd7859fa79343",
    "classIds": [
        [
            "65cb51e35a976f6c233eac70"
        ]
    ],
    "sort": 1,
    "createdBy": "admin",
    "updatedBy": "admin",
    "createdById": "64c8f0294d5a62d795b918a2",
    "updatedById": "64c8f0294d5a62d795b918a2",
    "createdAt": 1708916414573,
    "updatedAt": 1715029796236,
    "isDel": false,
    "isShow": true,
    "cardIdObj": "65dbfebc91ecd7859fa79343",
    "cardData": {
        "_id": "65dbfebc91ecd7859fa79343",
        "url": "https://github.com/",
        "title": "GitHub: Let’s build from here · GitHub",
        "icon": "https://github.githubassets.com/favicons/favicon.svg",
        "shortcutIcon": null,
        "appleTouchIcon": null,
        "route-pattern": "/",
        "route-controller": "dashboard",
        "route-action": "index",
        "current-catalog-service-hash": "40dc28bd654b20f337468a532ff456ed5863889cfbb4e982b793597321d48d3f",
        "request-id": "F5B5:16C6:42A3EF:4A24CF:65DBFEBB",
        "html-safe-nonce": "51945a7b90a1355d03a716c9ada304f7ece15897d079623d80776bdc858ded11",
        "visitor-payload": "eyJyZWZlcnJlciI6IiIsInJlcXVlc3RfaWQiOiJGNUI1OjE2QzY6NDJBM0VGOjRBMjRDRjo2NURCRkVCQiIsInZpc2l0b3JfaWQiOiI3MDYwMTI2MTYxMjM1MDgyOTM5IiwicmVnaW9uX2VkZ2UiOiJzb3V0aGVhc3Rhc2lhIiwicmVnaW9uX3JlbmRlciI6InNvdXRoZWFzdGFzaWEifQ==",
        "visitor-hmac": "85e1d46a3056fa45f458ebe7d37b682072837705cd03e7905b67601ff33aecbc",
        "page-subject": "GitHub",
        "github-keyboard-shortcuts": "dashboards,copilot",
        "google-site-verification": "Apib7-x98H0j5cPqHWwSMm6dNU4GmODRoqxLiDzdx9I",
        "octolytics-url": "https://collector.github.com/github/collect",
        "viewport": "width=device-width",
        "description": "GitHub is where over 100 million developers shape the future of software, together. Contribute to the open source community, manage your Git repositories, review code like a pro, track bugs and features, power your CI/CD and DevOps workflows, and secure code before you commit it.",
        "apple-itunes-app": "app-id=1477376905, app-argument=https://github.com/",
        "twitter:image:src": "https://github.githubassets.com/assets/campaign-social-031d6161fa10.png",
        "twitter:site": "@github",
        "twitter:card": "summary_large_image",
        "twitter:title": "GitHub: Let’s build from here",
        "twitter:description": "GitHub is where over 100 million developers shape the future of software, together. Contribute to the open source community, manage your Git repositories, review code like a pro, track bugs and fea...",
        "hostname": "github.com",
        "expected-hostname": "github.com",
        "turbo-cache-control": "no-preview",
        "is_logged_out_page": "true",
        "turbo-body-classes": "logged-out env-production page-responsive header-overlay home-campaign",
        "browser-stats-url": "https://api.github.com/_private/browser/stats",
        "browser-errors-url": "https://api.github.com/_private/browser/errors",
        "theme-color": "#1e2327",
        "createdBy": "admin",
        "updatedBy": "admin",
        "createdById": "64c8f0294d5a62d795b918a2",
        "updatedById": "64c8f0294d5a62d795b918a2",
        "createdAt": 1708916412052,
        "updatedAt": 1708916412052,
        "isDel": false
    }
  },
]

//
const SystemApp = () => {
  return (
    <>
      {/* 应用商店 */}
      <AppStore />

      {/* 偏好设置 */}
      <Settings />

      {/* 添加应用 */}
      <Add />

      {/* 测试应用 */}
      {/* {!isProduction && <Test />} */}

      {/* 浏览器 */}
      {/* {!isProduction && <Browser />} */}
    </>
  )
}

export default SystemApp
