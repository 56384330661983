import { Button, Tabs, Form, Input, Segmented, Space, message } from 'antd'
import { memo, useState, useMemo, useRef } from 'react'
import FormItmeRender from '@/components/formItmeRender'
import { userEMailVerificationCode, userLogIn, userRegister, userForget } from '@/api'
import { useRecoilState } from 'recoil'
import { imageCaptchaId as _imageCaptchaId, userInfo as _userInfo } from '@/state'
import ImageCaptcha from '@/components/verificationCode/imageCaptcha'
import VerificationCode from '@/components/verificationCode/verificationCode'
import { getReg, dynamicForm } from '@/tools'

// 未登录
const NotLogin = memo(() => {
	const [form] = Form.useForm()
	const childRef = useRef(null)
	const [imageCaptchaId] = useRecoilState(_imageCaptchaId) // 图片验证码
	const [, setUserInfo] = useRecoilState(_userInfo) // 用户信息

	// 页面类型
	const pageTypeOptions = [
		{ key: 'login', label: '登录' },
		{ key: 'register', label: '注册' },
		{ key: 'forget', label: '忘记密码' },
	]
	const [type, setType] = useState(pageTypeOptions[0].key) // 表单类型 注册 登录 忘记密码

	// 登录类型
	const loginTypeOptions = [
		{
			value: 'name',
			label: '用户名',
			rules: [{ required: true, message: '请输入' }],
		},
		{
			value: 'eMail',
			label: '邮箱',
			rules: [{ required: true, message: '请输入' }],
		},
		{
			value: 'phone',
			label: '手机',
			rules: [{ required: true, message: '请输入' }],
			disabled: true,
		},
	]
	const [loginType, setLoginType] = useState(loginTypeOptions[0].value) // 登录类型

	// 页面类型切换
	const titleChange = (v) => {
		setType(v)

		if (v !== 'login') {
			setLoginType('eMail')
		}
	}

	// 获取验证码(邮箱/手机)
	const getVerificationCode = async () => {
		const fd = form.getFieldsValue()

		const { code } = fd

		const arr = loginTypeOptions.filter((v) => v.value === loginType)
		const { value: name, label } = arr[0]

		if (!code) {
			message.error('请输入图形验证码')
			return false
		} else if (!getReg(name, 'rule')?.test(fd[name])) {
			message.error(`${label} 格式有误`)
			return false
		} else {
			const req = { _id: imageCaptchaId, code, loginType, type }
			req[name] = fd[name] //  邮箱 / 手机

			if (loginType === 'eMail') {
				// 通过<邮箱>注册
				const res = await userEMailVerificationCode(req)
				console.log(res)
				return true
			} else if (loginType === 'phone') {
				// 通过<手机>注册
			} else {
				return false
			}
		}
	}

	// 获取表单
	const formItemData = useMemo(() => {
		// 检查两次输入的密码是否相同
		const checkPassword = ({ getFieldValue }) => ({
			validator(_, value) {
				if (!value || getFieldValue('password') === value) {
					return Promise.resolve()
				}
				return Promise.reject(new Error('两次输入的密码不一致'))
			},
		})

		// 表单数据
		const formData = {
			name: {
				formItemProps: {
					rules: [{ required: true, message: '请输入' }],
					name: 'name',
				},
				render: <Input placeholder="用户名" allowClear />,
			},
			password: {
				formItemProps: {
					rules: [{ required: true, message: '请输入' }],
					name: 'password',
				},
				render: <Input.Password placeholder="密码" allowClear />,
			},
			confirmPassword: {
				formItemProps: {
					rules: [{ required: true, message: '请输入' }, checkPassword],
					name: 'confirmPassword',
				},
				render: <Input.Password placeholder="确认密码" allowClear />,
			},
			code: {
				formItemProps: {
					rules: [{ required: true, message: '请输入' }],
					name: 'code',
				},
				render: <ImageCaptcha placeholder="图形验证码" ref={childRef} />,
			},
			newPassword: {
				formItemProps: {
					rules: [{ required: true, message: '请输入' }],
					name: 'password',
				},
				render: <Input.Password placeholder="新密码" allowClear />,
			},
		}

		// 获取 (用户名 / 邮箱 / 手机) 表单
		const getUsernameOrEMailOrPhone = ({ name, label }) => ({
			formItemProps: {
				rules: [
					{ required: true, message: '请输入' },
					name ? getReg(name) : undefined,
				],
				name,
			},
			render: <Input placeholder={label} allowClear />,
		})

		// 邮箱验证码
		const getEMailVerificationCode = ({ label }) => ({
			formItemProps: {
				rules: [{ required: true, message: '请输入' }],
				name: 'eMailVerificationCode',
			},
			render: (
				<VerificationCode
					placeholder={`${label}验证码`}
					allowClear
					onClick={getVerificationCode}
				/>
			),
		})

		const arr = loginTypeOptions.filter((v) => v.value === loginType)
		const { value: name, label } = arr[0]

		switch (type) {
		case 'login': // 登录
			return [
				getUsernameOrEMailOrPhone({ name, label }),
				...dynamicForm(formData, ['password', 'code']),
			]

		case 'register': // 注册
		{
			const [itemName, ...remaining] = dynamicForm(formData, [
				'name',
				'password',
				'confirmPassword',
				'code',
			])
			getEMailVerificationCode({ label })

			return [
				itemName,
				getUsernameOrEMailOrPhone({ name, label }),
				...remaining,
				getEMailVerificationCode({ label }),
			]
		}


		case 'forget': // 忘记密码
			return [
				getUsernameOrEMailOrPhone({ name, label }),
				...dynamicForm(formData, ['newPassword', 'confirmPassword', 'code']),
				getEMailVerificationCode({ label }),
			]

		default:
			return []
		}
	}, [type, loginType, loginTypeOptions])

	// 提交
	const submit = async (formData) => {
		let res = null

		try {
			switch (type) {
				case 'login': // 登录
					res = await userLogIn({
						...formData,
						loginType,
						_id: imageCaptchaId,
					})
					// message.success('登录成功')
					break

				case 'register': // 注册
					res = await userRegister({ ...formData, loginType })
					message.success('注册成功')
					break

				case 'forget': // 忘记密码
					res = await userForget({ ...formData, loginType })
					message.success('操作成功')
					break

				default:
					break
			}
		} catch (error) {
			console.log(`${type} 错误:`, error)
			childRef?.current?.run?.()
			return
		}

		setUserInfo(res.data)
	}

	return (
		<>
			<Tabs
				activeKey={type}
				items={pageTypeOptions}
				onChange={titleChange}
			/>

			<Space direction="vertical" style={{ width: '100%' }} size={30}>
				{/* 登录/注册/忘记密码 类型选择 */}
				<Segmented
					block
					// 考虑到用户名可能会 让其他用户知晓 安全级别较低 所以找回密码时只允许使用 邮箱手机等不对外暴露的数据作为依据
					options={
						type === 'login' ? loginTypeOptions : loginTypeOptions.slice(1)
					}
					value={loginType}
					onChange={setLoginType}
				/>

				<Form
					form={form}
					// labelCol={{ span: 4 }}
					wrapperCol={{ span: 24 }}
					onFinish={submit}
					// size='large'
				>
					<FormItmeRender data={formItemData} />

					<Button block htmlType='submit'>提交</Button>
				</Form>
			</Space>
		</>
	)
})

export default NotLogin
