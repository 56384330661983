import { useRef, useEffect, useState, useMemo, memo } from 'react';
import { theme } from 'antd';
import styled from 'styled-components'

// 编写一个 react <角度选择器> 组件 要求:
// 1 可在antd 的form.item下正常使用(兼容antd表单的 initialValue / resetFields  / getFieldsValue / submit / 校验等事件)
// 2 选择器样式为圆环形 用户可点击或拖动圆环内的圆点改变所选角度 圆环的圆心处展示当前所选角度
// 3 选择器的返回值格式为 0deg ~ 360deg 的字符串 (参考css中 linear-gradient 的角度取值范围)
// 4 具体设计参考下图 但样式帮我改的更符合antd 的风格

const Text = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${props => props.$sizeOptions.fontSize};
  font-weight: ${props => props.$token.fontWeightStrong};
  color: ${props => props.$token.colorTextHeading};
  /* -webkit-text-stroke: 1px #fff; */
`

const AnglePicker = memo((props) => {
  const { value: propsValue, onChange: propsOnChange, size = 'middle', isShowText = true, ...restProps } = props;

  const [internalValue, setInternalValue] = useState(propsValue || '0deg');
  const [isDragging, setIsDragging] = useState(false);
  const circleRef = useRef(null);
  const { token } = theme.useToken();

  // 当外部 value 改变时更新内部状态
  useEffect(() => {
    if (propsValue !== undefined) {
      setInternalValue(propsValue);
    }
  }, [propsValue]);

  const calculateAngle = (event) => {
    const circle = circleRef.current;
    const rect = circle.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    
    const x = event.clientX - centerX;
    const y = event.clientY - centerY;
    
    let angle = Math.atan2(y, x) * (180 / Math.PI);

    // 计算角度时使用的是标准的数学坐标系统 而CSS的旋转是基于视觉设计的坐标系统 两者之间存在 90 度的差异
    angle = (angle - 90 + 360) % 360; // 加90度来修正差异
    
    return `${Math.round(angle)}deg`;
  };

  const handleValueChange = (newValue) => {
    setInternalValue(newValue);
    if (propsOnChange) {
      propsOnChange(newValue);
    }
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    setIsDragging(true);
    const newAngle = calculateAngle(event);
    handleValueChange(newAngle);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const newAngle = calculateAngle(event);
    handleValueChange(newAngle);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const numericAngle = parseInt(internalValue);

  // 尺寸选项
  const sizeOptions = useMemo(() => {
    const o = {
      small: {
        width: 40,
        height: 40,
        lineLength: 14,
        fontSize: token.fontSizeSM,
        stroke: 4,
      },
      middle: {
        width: 60,
        height: 60,
        lineLength: 22,
        fontSize: token.fontSizeLG,
        stroke: 6,
      },
      large: {
        width: 80,
        height: 80,
        lineLength: 28,
        fontSize: token.fontSizeXL,
        stroke: 8,
      },
    }
    return o[size]
  }, [size])


  return (
    <div 
      ref={circleRef}
      style={{
        width: sizeOptions.width,
        height: sizeOptions.height,
        position: 'relative',
        cursor: 'pointer',
      }}
      onMouseDown={handleMouseDown}
      {...restProps}
    >
      {/* 外圈圆环 */}
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: '50%',
        border: `${sizeOptions.stroke}px solid ${token.colorBorderSecondary}`,
      }} />
      
      {/* 角度线条 */}
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '2px',
        height: sizeOptions.lineLength,
        backgroundColor: token.colorPrimary,
        transformOrigin: 'top center',
        transform: `translateX(-50%) rotate(${numericAngle}deg)`,
      }}>
      </div>

      {/* 中间角度文字 */}
      {
        isShowText && <Text $sizeOptions={sizeOptions} $token={token}>{numericAngle}°</Text>
      }
    </div>
  );
});

export default AnglePicker;