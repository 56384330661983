import { useEffect, useCallback } from 'react'
import Home from './pages/home'
import { useRecoilState } from 'recoil'
import { userInfo as _userInfo, user as _user } from '@/state'
import { userSignOut } from '@/api'
import { globalExposure } from '@/tools'

// 入口文件(如果将来扩展 react-router-dom 相关功能则写在这里)
const App = () => {
  const [, setUserInfo] = useRecoilState(_userInfo) // 用户信息
  const [user, setUser] = useRecoilState(_user) // 用户信息

  // 全局拦截默认的右键菜单
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    // 添加全局事件监听器
    document.addEventListener('contextmenu', handleContextMenu);

    // 清理函数
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  const signOut = useCallback(async (isRequest = true) => {
    try {
			if (isRequest) {
				await userSignOut({ _id: user?._id })
			}

			setUserInfo(null)
			setUser(null)
    } catch (error) {
			console.error(error) // 使用console.error来记录错误
		}
	}, [user, setUserInfo, setUser]); // 确保依赖项正确

  // 向 window 下添加 signOut 方法
  useEffect(() => {
    globalExposure('signOut', signOut)
  }, [user, setUserInfo, setUser, signOut])

  return <Home />
}

export default App
