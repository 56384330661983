import styled from 'styled-components'
import { memo } from 'react'

const Box = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
`

const Ctn = memo((props) => <Box>{props.children}</Box>)

export default Ctn