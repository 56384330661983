import { memo, useEffect, useState, useRef, useCallback } from 'react'
import { Input, Button } from 'antd'
import styled from 'styled-components'

const FormItemRender = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledInput = styled(Input)`
  flex: 1;
`

// 邮箱验证码-按钮
const StyledButton = styled(Button)`
  margin-left: 5px;
`

// 手机/邮箱验证码 + 获取按钮
const VerificationCode = memo(({ onClick, ...props }) => {
  const btnDefaultText = '获取验证码' // 按钮默认文案
  const [btnText, setBtnText] = useState(btnDefaultText) // 按钮文案
  const intervalRef = useRef(null) // 定时器引用

  // 获取验证码
  const getVerificationCode = useCallback(async () => {
    // 防止外层逻辑报错导致定时器无法执行
    try {
      let res = false
      try {
        res = await onClick?.() // 调用外层传入的函数 并获得校验结果
      } catch (error) {
        console.log(error)
      }

      if (res) {
        if (intervalRef.current !== null) clearInterval(intervalRef.current)

        const i = setInterval(() => {
          setBtnText((prevText) => {
            if (prevText === btnDefaultText) {
              return 60
            } else if (prevText === 1) {
              clearInterval(intervalRef.current)
              return btnDefaultText
            } else {
              return prevText - 1
            }
          })
        }, 1000)

        intervalRef.current = i
      }
    } catch (error) {
      console.log('获取验证码报错', error)
    }
  }, [onClick])

  // 清理定时器防止内存泄漏
  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  return (
    <FormItemRender>
      <StyledInput allowClear {...props} />

      <StyledButton
        onClick={getVerificationCode}
        disabled={btnText !== btnDefaultText}
      >
        {btnText !== btnDefaultText ? `${btnText}秒后重新获取` : btnText}
      </StyledButton>
    </FormItemRender>
  )
})

export default VerificationCode