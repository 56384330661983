import { memo } from 'react'
import { Typography, Image } from 'antd'
import styled from 'styled-components'

const { Title } = Typography

const TitleBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
`

const TitleLeft = styled.div`
  flex: 1;
  padding-left: 10px;
`

const StyledTitle = styled(Title)`
  margin-bottom: 10px;
  font-size: 26px;
`

const StyledText = styled(Typography)`
  font-size: 17px;
`

const TitleRight = styled.div`
  align-self: stretch;
  width: ${props => props.$width}px;
  min-height: 110px;
  background-image: url(${props => props.$src});
  background-size: ${props => props.$width}px auto;
  background-repeat: no-repeat;
  background-position: bottom right;
`

const TitleBox = memo((props) => {
  const { src, title, desc, width = 300 } = props

  return (
    <TitleBoxWrapper>
      <TitleLeft>
        <StyledTitle level={3}>{title}</StyledTitle>
        <StyledText>{desc}</StyledText>
      </TitleLeft>

      <TitleRight $src={src} $width={width}>
        {/* <Image
          preview={false}
          width={width}
          src={src}
        /> */}
      </TitleRight>
    </TitleBoxWrapper>
  )
})

export default TitleBox