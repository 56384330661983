/*
  右键菜单封装

  基于react / styled-components 封装右键菜单组件:
    1 使用时使用菜单组件包裹其他内容 当菜单组件或其内容被右键点击时展示菜单
      例如: <menu> ... </menu>
    2 菜单选项以 props 的 data 字段传入 传入的参数为数组对象, 例如: [{ label: '修改', value: 1 }, { label: '删除', value: 2 }]
    3 右键单击 展示菜单时要校验当前位置 如果空间够用菜单左上角位置对应鼠标单击处的坐标 菜单列表 如遇空间不够自动切换至右上 / 左上 / 左下 等方向弹出展示内容
    4 菜单点击事件需要封装 具体点击逻辑有用户传入
    5 菜单展示时自动聚焦 失去焦点时隐藏菜单(用户点击其他地方等行为关闭菜单)
*/

import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

// 菜单容器
const MenuContainer = styled.div`
  position: fixed;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1000;
`;

// 菜单项
const MenuItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  min-width: 120px;
  &:hover {
    background-color: #669bf7;
    color: #fff;
  }
`;

// 上下文菜单
const ContextMenu = ({ children, data, onMenuItemClick }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const menuRef = useRef(null)
  const containerRef = useRef(null)

  // 计算位置
  const calculatePosition = (x, y) => {
    if (!menuRef.current) return { x, y }

    const menuRect = menuRef.current.getBoundingClientRect()
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    let newX = x
    let newY = y

    // 检查水平空间
    if (x + menuRect.width > windowWidth) {
      newX = x - menuRect.width
    }

    // 检查垂直空间
    if (y + menuRect.height > windowHeight) {
      newY = y - menuRect.height
    }

    return { x: newX, y: newY }
  }

  // 处理上下文菜单
  const handleContextMenu = (e) => {
    e.preventDefault()
    const { x, y } = calculatePosition(e.clientX, e.clientY)
    setPosition({ x, y })
    setIsVisible(true)
  }

  // 点击事件
  const handleClick = (item) => {
    onMenuItemClick && onMenuItemClick(item)
    setIsVisible(false)
  }

  // 点击外部关闭
  const handleClickOutside = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsVisible(false)
    }
  }

  // 初始化时绑定关闭事件
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={containerRef} onContextMenu={handleContextMenu}>

      {children}

      {isVisible && (
        <MenuContainer
          ref={menuRef}
          style={{
            left: position.x,
            top: position.y,
          }}
        >
          {data.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => handleClick(item)}
            >
              {item.label}
            </MenuItem>
          ))}
        </MenuContainer>
      )}
    </div>
  )
}

export default ContextMenu