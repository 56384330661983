import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { RecoilRoot } from 'recoil' // 轻量级全局状态管理工具
import InjectionStatus from '@/state'
import GlobalStyle from '@/styles/globalStyles' // 全局样式
import microApp from '@micro-zoe/micro-app' // 微前端

// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import zhCN from 'antd/locale/zh_CN'

import StrictMode from '@/components/strictMode'
import ShieldDragHint from '@/components/err/shieldDragHint'
import WindowResize from '@/components/windowResize'

window.__app__ = {}
dayjs.locale('zh-cn')

microApp.start()
/*
microApp.router.beforeEach((to, from, appName) => {
  console.log('全局前置守卫 beforeEach: ', to, from, appName)
})
microApp.router.afterEach((to, from, appName) => {
  console.log('全局后置守卫 afterEach: ', to, from, appName)
})
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <StrictMode>
      <ConfigProvider locale={zhCN}>
        {/* 隐藏 Drag 不必要的报错信息 */}
        <ShieldDragHint />

        {/* 注入全局样式 */}
        <GlobalStyle />

        {/* 暴露部分全局状态到 window.__app__ 方便在js文件中调用 */}
        <InjectionStatus />

        {/* 初始化&窗口尺寸变化时 获取用的 系统 / 是否移动端 / 视口尺寸 */}
        <WindowResize />

        {/* 页面 */}
        <App />
      </ConfigProvider>
    </StrictMode>
  </RecoilRoot>
);
