import React, { useEffect, useMemo, useState, memo } from 'react';
import Modal from '@/components/modal';
import { useRecoilState } from 'recoil'
import { openApp as _openApp, userInfo as _userInfo } from '@/state'
import { appIds, DOMIds } from '@/tools'
import { classOfficialAll, carouselAll } from '@/api'
import { Carousel } from 'antd'
import Elevator from '@/components/elevator'
import SearchBar from '@/components/twoColLayout/components/SearchBar'
import styled from 'styled-components'
import Group from './components/group'
import SearchResList from './components/searchResList'

const Ctn = styled.section`
  width: 100%;
`

// 应用列表
const AppList = memo(({ classData, isShowTitle }) => {
  return (
    <Ctn>
      {
        classData?.map((v, index) => (
          <Group
            key={v?._id}
            classData={classData}
            index={index}
            isShowTitle={isShowTitle}
          />
        ))
      }
    </Ctn>
  )
})

const AppStore = () => {
  const [openApp] = useRecoilState(_openApp) // 打开的应用
  const [userInfo] = useRecoilState(_userInfo) // 用户信息
  const [classData, setClassData] = useState([])
  const [keyword, setKeyword] = useState()
  const [carouselData, setCarouselData] = useState([]) // 轮播图数据
  const [currentcarouselData, setCurrentCarouselData] = useState(null) // 当前选择的轮播图数据
  const [searchResultsTitle, setSearchResultsTitle] = useState('') // 搜索结果 | 轮播图标题

  // 应用商店是否打开状态
  const isOpen = useMemo(() => openApp?.includes(appIds.appStore), [appIds, openApp])

  useEffect(() => {
    if (isOpen) {
      // 初始化 分类数据
      classOfficialAll().then(res => {
        setClassData(res?.data?.rows || [])
      })

      // 初始化轮播图数据
      carouselAll().then(res => {
        setCarouselData(res?.data?.rows || [])
      })
    } else {
      setKeyword(undefined)
    }
  }, [isOpen])

  const data = useMemo(() => {
    const res = [
      classData?.map(v => ({
        id: v._id,
        title: v.label,
        isEnableAnchor: true,
      })) || []
    ]
    return res
  }, [userInfo, classData])

  // 点击轮播图
  const clickCarousel = (v) => {
    setSearchResultsTitle(v?.title || '')
    setCurrentCarouselData(v)
    setKeyword(undefined)
  }

  // 搜索栏change
  const searchBarChange = (e) => {
    setSearchResultsTitle('')
    const keyword = e?.target?.value || undefined
    setKeyword(keyword)
    setCurrentCarouselData(null)
  }

  return (
    <Modal
      open={isOpen}
      getContainer={`#${DOMIds.main}`}
      title={<h3>应用商店</h3>}
      isMaximized={true}
      appId={appIds.appStore}
    >
      <Elevator leftflex={1} rightflex={2} data={data}>
        <div style={{ paddingTop: 20 }}>
          <Carousel arrows={true} autoplay={3000}>
            {
              carouselData.map(v => {
                return (
                  <div
                    key={v._id}
                    onClick={() => clickCarousel(v)}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                        height: 200,
                        backgroundImage: `url(${encodeURI(v.imgUrl)})`, // 直接在这里构建 url()
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                      }}
                    />
                  </div>
                )
              })
            }
          </Carousel>
        </div>

        <SearchBar
          value={keyword}
          onChange={searchBarChange}
          allowClear={true}
          size='large'
          triggerCeilingEffectThreshold={0}
          scrollContainerSelector={'.elevator-right'}
        />

        {/* 应用列表 */}
        {
          keyword || currentcarouselData ?
            // 轮播&搜索 结果
            <SearchResList
              keyword={keyword}
              setKeyword={setKeyword}
              _id={currentcarouselData?.userCardId}
              setCurrentCarouselData={setCurrentCarouselData}
              title={searchResultsTitle}
            /> :

            // 分类: 应用列表
            <AppList classData={classData} />
        }
      </Elevator>
    </Modal>
  )
}

export default AppStore