import { Flex } from 'antd'
import { useState, memo } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import { openApp as _openApp, dockerData as _dockerData } from '@/state'
import { DraggableMenuCard } from '@/components/card'

const Docker = styled.div`
  padding: 12px 6px;
  border-radius: 10px;
  background-color: #ffffff40;
  height: 64px;

  @media (max-width: 768px) {
    max-width: 90vw;
  }
  @media (min-width: 769px) {
    max-width: 80vw;
  }
`

// 每个card左右两侧的占位符
const Placeholder = styled.div`
  width: calc(8px / 2);
  height: 40px;
  background: transparent;
  transition: all 0.3s;
`

// 弹性盒子
const Roll = styled(Flex)`
  padding: 0 6px;
  height: 75px;
  position: relative;
  bottom: 35px;

  overflow-x: auto;

  /* 隐藏滚动条的滑块 */
  &::-webkit-scrollbar {
    display: none;
  }

  /* 隐藏滚动条的轨道 */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`

// 列表项
const Item = styled(Flex)`
  --scale: 1;
  padding-bottom: calc((var(--scale) * 10px) - (10px / var(--scale)));
  transition: all 0.3s;
  display: flex;
  align-items: flex-end;
  height: 100%;
`

const Card = styled.div`
  width: calc(40px * var(--scale));
  height: calc(40px * var(--scale));
  transition: all 0.3s;
`

const Footer = memo((props) => {
  const { background, height = 90 } = props

  const [dockerData] = useRecoilState(_dockerData) // 底部docker栏卡片数据
  const [openApp] = useRecoilState(_openApp)
  const [cHoverKey, setCHoverkey] = useState(-10) // 当前指入的卡片
  const CardSize = 40

  // 获取所有的item
  const getItem = () => {
    return [...document.getElementsByClassName('docker-item')]
  }

  // 鼠标移入dockerItem
  const onMouseOver = (index) => {
    setCHoverkey(index)
    const items = getItem()
    items.forEach((v, i) => {
      if (i === index - 2) {
        v.style.setProperty('--scale', '1.1')
      } else if (i === index - 1) {
        v.style.setProperty('--scale', '1.3')
      } else if (i === index) {
        v.style.setProperty('--scale', '1.5')
      } else if (i === index + 1) {
        v.style.setProperty('--scale', '1.3')
      } else if (i === index + 2) {
        v.style.setProperty('--scale', '1.1')
      }
    })
  }

  // 鼠标移出dockerItem
  const onMouseLeave = () => {
    setCHoverkey(-10)

    const items = getItem()
    items.forEach(v => v.style.setProperty('--scale', '1'))
  }

  const calcSize = (k) => {
    const size = { width: `calc(${CardSize}px * var(--scale))`, height: `calc(${CardSize}px * var(--scale))` }

    if (cHoverKey === k - 2) {
      return { '--scale': 1.1, ...size }
    } else if (cHoverKey === k - 1) {
      return { '--scale': 1.3, ...size }
    } else if (cHoverKey === k) {
      return { '--scale': 1.5, ...size }
    } else if (cHoverKey === k + 1) {
      return { '--scale': 1.3, ...size }
    } else if (cHoverKey === k + 2) {
      return { '--scale': 1.1, ...size }
    } else {
      return { '--scale': 1, ...size }
    }
  }

  return (
    <footer style={{ background: background, height: height, position: 'relative', zIndex: 2 }}>
      <Flex style={{ height: '100%' }} justify='center' align='center'>
        <Docker>
          <Droppable
            droppableId={'docker'}
            key={'docker'}
            type="PERSON"
            direction='horizontal' // 排列方向
            isCombineEnabled={false} // 是否允许合并
          >
            {provided => (
              <Roll
                {...provided.droppableProps}
                ref={provided.innerRef}
                justify='flex-start'
                align='center'
              >
                {
                  dockerData?.map?.((v, k) => (
                    <Item
                      className='docker-item'
                      key={v._id}
                      onMouseOver={() => onMouseOver(k)}
                      onMouseLeave={() => onMouseLeave(k)}
                    >
                      {k !== 0 && <Placeholder />}

                      <Card>
                        <DraggableMenuCard
                          v={v}
                          k={k}
                          model={'min'}
                          isDrop={true}
                          picStyle={{ transform: 'none', transition: 'all 0.3s', ...calcSize(k) }}
                          isShowRun={openApp.includes(v?._id?.split?.('-')?.[1])}
                        />
                      </Card>

                      {k !== dockerData?.length - 1 && <Placeholder />}
                    </Item>
                  ))
                }

                {/* 占位符-确保 没有 docker 数据的时候 也至少有一个具备宽高的空盒子可以放入 card */}
                {!dockerData?.length && <div style={{ minWidth: '20vw' }} />}
                {provided.placeholder}
              </Roll>
            )}
          </Droppable>
        </Docker>
      </Flex>
    </footer>
  )
})

export default Footer