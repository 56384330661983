import styled from 'styled-components';

const Title = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 显示为省略号(...) */
  color: ${props => props.$active ? '#fff' : '#333'};
`;

export default function Tle (props)  {
  const { active = false } = props

  return <Title $active={active}>{props.children}{  }</Title>
}