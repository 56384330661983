// src/styles/globalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /* 原生标签 公共样式 调整 */

  * {
    box-sizing: border-box;
    // outline: 1px solid red;
  }

  html,
  body,
  div,
  ul,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  dl,
  dt,
  dd,
  ol,
  form,
  input,
  textarea,
  th,
  td,
  select {
    margin: 0;
    padding: 0;
  }

  html,
  body {
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }

  ul,
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  body {
    font-family: "Microsoft YaHei";
    font-size: 14px;
    color: #333;
  }

  img {
    border: none;
    vertical-align: middle;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
  }

  input,
  textarea {
    outline: none;
    border: none;
  }

  textarea {
    resize: none;
    overflow: auto;
  }

  .clearfix {
    zoom: 1;
  }

  .clearfix:after {
    content: ".";
    width: 0;
    height: 0;
    visibility: hidden;
    display: block;
    clear: both;
    overflow: hidden;
  }

  .fl {
    float: left;
  }

  .fr {
    float: right;
  }

  .tl {
    text-align: left;
  }

  .tc {
    text-align: center;
  }

  .tr {
    text-align: right;
  }

  .ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .inline {
    display: inline-block;
    *display: inline;
    *zoom: 1;
  }

  body > iframe {
    display: none;
  }

  /* 全局样式表 */
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, .8) !important; /* 使用 !important 防止被其他样式覆盖 */
  }
  .ant-modal-header{
    height: 0;
  }

  .ant-avatar.ant-avatar-square{
    border-radius: 16px;
  }
`;

export default GlobalStyle;
