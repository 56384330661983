import * as Icon from '@ant-design/icons'
import React from 'react'
import dayjs from 'dayjs'

// 窗口大小监听
export const onWindowSize = (dom, set) => {
	let _ = null

	// 是element直接使用 否则先获取元素
	if (dom instanceof HTMLElement) {
		_ = dom
	} else {
		_ = document.querySelector(dom)
	}

	if (_ instanceof HTMLElement) {
		const res = new ResizeObserver(([entry] = []) => {
			const [size] = entry.borderBoxSize || []
			set({ width: size.inlineSize, height: size.blockSize })
		}).observe(_) // 通过监听 document.body 来实现监听窗口大小变化

		return res
	} else {
		setTimeout(() => {
			return onWindowSize(dom, set)
		}, 100)
	}
}

// 用于加载URL并返回一个Promise
const className = 'iFrameRace'
function _loadURL(url) {
	return new Promise((resolve, reject) => {
		// 创建一个iframe元素
		const iframe = document.createElement('iframe')

		// 设置iframe的src属性为给定的URL
		iframe.src = url

		// 监听load事件，当iframe加载完成时解析Promise
		iframe.onload = () => resolve(url)

		// 监听error事件，如果加载失败则拒绝Promise
		iframe.onerror = () => reject(new Error(`Failed to load ${url}`))    // 将iframe添加到页面中（可以将其设置为不可见）

		iframe.style.display = 'none'
		iframe.className = className

		document.body.appendChild(iframe)    // 设置iframe的样式，使其不可见
	})
}
// 找到最快响应的URL
export async function findFastestURL(urls) {  // 使用map创建一个Promise数组  
	const promises = urls.map(url => _loadURL(url))  // 使用Promise.race来获取最快完成的Promise

	const res = await Promise.race(promises)

	// 删除类名为 iFrameRace 的iframe
	setTimeout(() => {
		const ele = document.querySelectorAll(`.${className}`)
		Array.from(ele)?.map?.(v => document.body.removeChild(v))
	}, 0)

	return res
}

// 将扁平数组按照指定的行列数量转化成二维数组
export function create2DArray(flatArray, cols, rows) {
	// 首先检查输入的数组长度是否符合行数和列数的乘积
	const requiredLength = rows * cols

	const arr = structuredClone(flatArray)
	while (arr.length < requiredLength) {
		arr.push({})
	}

	// 创建一个空的二维数组
	const result = []

	// 遍历数组，按照行和列的规则填充二维数组
	for (let i = 0; i < rows; i++) {
			let row = []
			for (let j = 0; j < cols; j++) {
					// 计算当前元素在扁平数组中的索引
					let index = i * cols + j
					row.push(arr[index])
			}
			result.push(row)
	}

	return result
}

// 将扁平数组按照指定的行列数量转化成三维数组
export function createTo3DArray(arr, cols, rows) {
	const result = [];
	const totalItems = arr.length;
	const itemsPerPage = cols * rows;
	const pages = Math.ceil(totalItems / itemsPerPage);

	for (let p = 0; p < pages; p++) {
			const page = [];
			for (let r = 0; r < rows; r++) {
					const row = [];
					for (let c = 0; c < cols; c++) {
							const index = p * itemsPerPage + r * cols + c;
							if (index < totalItems) {
									row.push(arr[index]);
							} else {
									row.push(undefined);
							}
					}
					page.push(row);
			}
			result.push(page);
	}

	return result;
}

export const Docker = 'docker'
export const Folder = 'folder'
// 对卡片数据 按照用户设置进行排序
export function sortCardData (cardData, sort) {
	// console.log('排序条件', cardData, sort)

	if (!sort?.length) {
		return cardData
	}

	// 利用 _id 关联数据 方便查询
	const memo = {}
	cardData.map(v => {
		memo[v._id] = v
	})

	const res = []

	sort.map((_idOrFolder) => {
		const val = memo[_idOrFolder]

		if (typeof _idOrFolder === 'string' && val) {
			res.push(val)

			delete memo[_idOrFolder] // 一边处理一边剪枝 最终如果 memo 有剩余数据 说明存在 排序数据丢失的情况 应push 到队尾(既插入最后)
		} else {
			const { _id, title, type, data } = _idOrFolder ?? {}

			if (_id) {
				const childrenFilling = []
				// console.log('data', data)

				data?.map(_id => {
					// console.log('_id', _id)
					const item = memo[`${_id}`]

					delete memo[`${_id}`]
					if (item) {
						childrenFilling.push(item)
					}
				})
	
				// console.log('childrenFilling', childrenFilling)
	
				if (childrenFilling.length) {
					res.push({
						_id,
						title,
						type,
						data: childrenFilling,
					})
				}
			}
		}
	})

	if (!isEmptyObject(memo)) {
		for (const key in memo) {
			res.push(memo[key])
		}
	}

	// console.log('排序结果', res)
	return res
}

// 判断对象是否为空
export function isEmptyObject(obj) {
	for (let key in obj) {
		if (obj.hasOwnProperty(key)) {
			return false;
		}
	}
	return true;
}

// 移出数组中的空对象
export function removeEmptyObjects(arr) {
	// 使用filter方法筛选出非空对象
	const nonEmptyObjects = arr.filter(obj => !isEmptyObject(obj));

	// 使用concat方法将空对象添加到非空对象后面
	// const emptyObjects = arr.filter(isEmptyObject);
	// return nonEmptyObjects.concat(emptyObjects);

	return nonEmptyObjects
}

// 冷却的方式处理事件频繁触发问题
export const debounced = (event, fun, timer, time = 1500) => {
	if (timer.current) {
		// 如果在50ms冷却期内，直接返回
		return
	}

	// 执行handleWheel函数
	fun(event)

	// 设置冷却标志位为true，并在1500ms后重置为false (因为mac滚动的时候 会有系统自带的 <橡皮筋> 效果)
	timer.current = true
	setTimeout(() => {
		timer.current = false
	}, time)
}

// 防抖
export function debounce(func, wait) {
  let timeout

  return function() {
    const context = this, args = arguments
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      func.apply(context, args)
    }, wait)
  }
}

// 判断用户是否是移动端 操作系统
export function getIsMobileDevice () {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 常见的移动端设备标识
  const mobileDevices = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  // 检查用户代理字符串是否匹配移动端设备标识
  return mobileDevices.some((device) => device.test(userAgent));
}

// 获取用户的操作系统
export const getDetectOS = () => {
  const userAgent = navigator.userAgent

	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return "iOS"
	} else if (userAgent.includes("Android")) {
		return "Android"
	} else if (userAgent.includes("Macintosh")) {
    return "Mac"
  } else if (userAgent.includes("Windows")) {
    return "Windows"
  } else if (userAgent.includes("Linux")) {
    return "Linux"
  } else {
		return "Unknown"
	}
}

// 判断色值是否为浅色
export function isLightColor (color, threshold = .5) {
  // 解析颜色值
  let r, g, b, a = 1;
  
  // 处理RGB和RGBA格式
  if (color.startsWith('rgb')) {
    const parts = color.match(/\d+/g).map(Number);
    [r, g, b] = parts;
    if (parts.length === 4) a = parts[3];
  } 
  // 处理HEX格式
  else if (color.startsWith('#')) {
    const hex = color.slice(1);
    if (hex.length === 3 || hex.length === 4) {
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
      if (hex.length === 4) a = parseInt(hex[3] + hex[3], 16) / 255;
    } else if (hex.length === 6 || hex.length === 8) {
      r = parseInt(hex.slice(0, 2), 16);
      g = parseInt(hex.slice(2, 4), 16);
      b = parseInt(hex.slice(4, 6), 16);
      if (hex.length === 8) a = parseInt(hex.slice(6, 8), 16) / 255;
    }
  } else {
		return undefined
    // throw new Error('Invalid color format');
  }
  // 计算亮度
  // 使用相对亮度公式: (0.299*R + 0.587*G + 0.114*B)
  // 考虑透明度: 将颜色与白色混合
  const luminance = (0.299*r + 0.587*g + 0.114*b) / 255;
  const alphaBlendedLuminance = luminance * a + (1 - a);

  // 判断是否为浅色
  return alphaBlendedLuminance > threshold;
}

// 小屏宽度阈值
export const smallScreenWidthThreshold = 500

// app
export const appIds = {
	browser: 'browser',
	settings: 'settings',
	appStore: 'appStore',
	test: 'test',
	add: 'add',
}

// DOM id & class
export const DOMIds = {
	main: 'main',
	'elevator-right': 'elevator-right',
}

// 新标签页name
export const NewTab = 'New Tab'

// 初始化tab
export const InitTab = { id: 1, url: '', title: NewTab, src: [] }

// 提高指定模态窗的zIndex
export const modalUp = ({ e, modalZIndex, appId, setModalZIndex }) => {
	e?.stopPropagation?.() // 阻止冒泡防止事件蔓延到 轮播图 组件上

	const arr = Object.entries(modalZIndex)

	if (!arr.length) {
		return
	}

	arr.sort((a, b) => b[1] - a[1]) // 按 z-index 排序

	const newModalZIndex = structuredClone(modalZIndex)

	// 当前窗口已是最高层级时什么也不做
	if (arr[0][0] === appId) {
		return
	}

	newModalZIndex[appId] = arr[0][1] + 1
	setModalZIndex(newModalZIndex)
}

// _id 加前缀 / 减前缀
export const idPrefixHandle = ({ o, prefix, type }) => {
	if (type === 'add') {
			return {...o, _id: `${prefix}-${o._id}`}
	} else if (type === 'reduce') {
			return {...o, _id: o._id.split('-')[1]}
	}
}

// 正则
export const regData = (() => {
	const data = [
		{
			key: 'phone',
			name: '中国(宽松) 只要是13/14/15/16/17/18/19开头即可',
			rule: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
			message: '内容格式错误',
		},
		{
			key: 'eMail',
			name: '邮箱',
			rule: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			message: '内容格式错误',
		},
		{
			key: 'url',
			name: '网址',
			rule: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/,
			message: '内容格式错误',
		},
	]

	const res = {}
	for (const { rule, message, key } of data) {
		res[key] = {
			rule,
			formItemRule: {
				validator: (_, value) => {
					if (!value || rule.test(value)) {
						return Promise.resolve()
					}
					return Promise.reject(message)
				},
			},
		}
	}
	return res
})()

// 选择一条正则返回
export const getReg = (key, type = 'formItemRule') => regData?.[key]?.[type]

// 按key动态组成表单
export const dynamicForm = (formData, keys) => keys.map((key) => formData[key])

// 所有icon名称
export const iconNames = Object.keys(Icon).slice(0, 790) // 最后四个不是iconName会导致报错 => createFromIconfontCN, default, getTwoToneColor, setTwoToneColor

// 传入icon名称返回 <Icon /> 标签
export const icon = (iconName) => Icon?.[iconName] ? React.createElement(Icon[iconName]) : null

// 时间格式化
export const dateToStr = (v, type = 'YYYY-MM-DD HH:mm:ss') => v ? dayjs(v).format(type) : ''

// dayjs 转 毫秒级时间戳
export const dayToTimestamp = (d) => {
	if (typeof d === 'object' && d.$d) {
		return d.valueOf()
	} else {
		return d
	}
}

// 多维数组过滤
export function multidimensionalArrayFilter(arr, filterFn) {
  return arr.reduce((filtered, item) => {
    if (Array.isArray(item)) {
      const nestedFiltered = multidimensionalArrayFilter(item, filterFn);
      if (nestedFiltered.length > 0) {
        filtered.push(nestedFiltered);
      }
    } else if (filterFn(item)) {
      filtered.push(item);
    }
    return filtered;
  }, []);
}

// 随机抽取两个数字之间的一个数字
export const betRandom = (min, max, isRound = true) => {
	const res = Math.random() * (max + 1 - min)

	if (isRound) {
		return Math.floor(res) + min
	} else {
		return res
	}
}

// 获取当前页面查询字符串并转为对象结构
export function getQueryParams() {
  // 获取查询字符串
  const queryString = window.location.search;

  // 创建一个URLSearchParams对象
  const urlParams = new URLSearchParams(queryString);

  // 创建一个空对象，用于存储参数键值对
  const params = {};

  // 遍历URLSearchParams对象，将键值对存入params对象
  urlParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
}

// 是否生产环境
export const isProduction = process.env.NODE_ENV === 'production' // 'development'


// 替换线性渐变字符串中的角度参数 'linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)' 90deg 参数替换
export function replaceGradientDegree(cssString, newDegree) {
  // 正则表达式匹配 linear-gradient 函数中的度数值
  const regex = /linear-gradient\((\d+)deg/;
  // 使用正则表达式替换度数值
  const newCssString = cssString?.toCssString()?.replace(regex, `linear-gradient(${newDegree}`) || undefined;
  return newCssString;
}

// 判断一个字符串是否是 rgb色值 或 线性渐变色值
export function isColorOrGradient(cssString) {
  // 正则表达式匹配 rgb() 或 linear-gradient()
  const regex = /(rgb\(\d{1,3},\s*\d{1,3},\s*\d{1,3}\))|(linear-gradient\((?:\d{1,3},\s*){2}rgb\(\d{1,3},\s*\d{1,3},\s*\d{1,3}\)\s*\d+%,\s*rgb\(\d{1,3},\s*\d{1,3},\s*\d{1,3}\)\s*\d+%\))/g;
  return regex.test(cssString);
}

// js 封装一个函数将线性渐变字符串 转为对象 格式
/*
	例如: 'linear-gradient(90deg, rgb(230,230,230) 0%, rgb(0,233,11) 100%)'
	转为: {
		deg: '90deg',
		color: [
			{ color: 'rgb(16, 142, 233)', percent: 0 },
			{ color: 'rgb(135, 208, 104)', percent: 100 },
		],
	}
*/
export function parseLinearGradient(gradientString) {
  // 正则表达式匹配线性渐变的度数值、颜色值和百分比
  const degreeRegex = /(\d+)deg/;
  const colorStopsRegex = /rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)\s*(\d+)%/g;

  // 提取度数值
  const degreeMatch = gradientString.match(degreeRegex);
  const angle = degreeMatch ? `${degreeMatch[1]}deg` : null;

  // 提取颜色值和百分比
  const colorStops = [];
  let colorStopMatch;
  while ((colorStopMatch = colorStopsRegex.exec(gradientString)) !== null) {
    colorStops.push({
      color: `rgb(${colorStopMatch[1]}, ${colorStopMatch[2]}, ${colorStopMatch[3]})`,
      percent: parseInt(colorStopMatch[4], 10)
    });
  }

  // 构建并返回对象
  return {
    angle,
    color: colorStops
  };
}

// 向全局 window 暴露 api attr
export const globalExposure = (key, val) => {
	if (window?.__app__) {
		window.__app__[key] = val
	} else {
		const o = {}
		o[key] = val
		window.__app__ = o
	}
}
