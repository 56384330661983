import { memo, useState, useMemo } from 'react'
import Pic from '@/components/pic'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import {  Button, Typography, Flex } from 'antd'
import styled from 'styled-components'
import ProgressBar from '@/components/progressBar'
import { useRecoilState } from 'recoil'
import { haveCardList as _haveCardList, cardList as _cardList } from '@/state'
import { userCardAdd, userCardDelBind } from '@/api'


const AppItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
`;

const AppInfo = styled.div`
  width: 50%;
  flex-grow: 1;
  margin-left: 10px;
`;

const AppName = styled.div`
  width: 100%;
  font-weight: bold;
  margin-bottom: 4px;
  overflow: hidden;      /* 隐藏溢出的内容 */
  white-space: nowrap;   /* 不允许文本换行 */
  text-overflow: ellipsis;/* 用省略号表示溢出的文本 */
`;

const AppDescription = styled.p`
  width: 100%;
  font-size: 12px;
  color: #666;
  overflow: hidden;      /* 隐藏溢出的内容 */
  white-space: nowrap;   /* 不允许文本换行 */
  text-overflow: ellipsis;/* 用省略号表示溢出的文本 */
`;

const Card = memo((props) => {
  const {app, index} = props
  const [haveCardList] = useRecoilState(_haveCardList) // 当前用户拥有的卡片_id
  const [cardList] = useRecoilState(_cardList) // 当前用户全量卡片数据
  const { _id, cardData = {}, customizeTitle, customizeDescription } = app
  const { url, title, description, icon, shortcutIcon, appleTouchIcon } = cardData
  const [downloading, setDownloading] = useState({}) // 下载中

  const renderTitle = useMemo(() => {
    return customizeTitle || title
  }, [customizeTitle, title])

  const renderDescription = useMemo(() => {
    return customizeDescription || description
  }, [customizeDescription, description])

  // 添加应用
  const add = async (app, index) => {
    const { cardData } = app

    setDownloading(v => {
      const res = structuredClone(v)
      res[index] = true
      return res
    })


    try {
      const req = {
        classIds: [],
        cardId: cardData._id,
        type: app.type, // 继承 admin 创建者添加的类型
        appId: app.appId, // 继承 admin 创建者添加的类型
        sort: 1,
        customizeIcon: undefined,
        customizeTitle: undefined,
        customizeDescription: undefined,
      }
      await userCardAdd({ data: req })
      await window?.__app__?.refreshDesktop?.() // 刷新桌面
    } catch (error) {
      console.error(error)
    }

    setDownloading(v => {
      const res = structuredClone(v)
      res[index] = false
      return res
    })
  }

  // 删除
  const del = async (cardId, index) => {
    setDownloading(v => {
      const res = structuredClone(v)
      res[index] = true
      return res
    })

    try {
      let target = null // 查找当前用自己的这张卡片

      for (const i of cardList) {
        if (i.cardId === cardId) {
          target = i
          break
        }
      }

      if (target) {
        await userCardDelBind({ _id: target._id })
        window?.__app__?.refreshDesktop?.()
      }
    } catch (error) {
      console.error(error)
    }

    setDownloading(v => {
      const res = structuredClone(v)
      res[index] = false
      return res
    })
  }

  // 跳转
  const onClick = (url) => {
    window.open(url)
  }

  return (
    <AppItem key={_id} onClick={() => onClick(url)}>
      <Pic
        src={[appleTouchIcon, shortcutIcon, icon]}
        backgroundColor={cardData['theme-color']}
        alt={renderTitle}
        picStyle={{ width: 50, height: 50 }}
      >
        {renderTitle}
      </Pic>

      <AppInfo>
        <AppName>
          <Typography.Text ellipsis={true} style={{ width: '100%' }}>{renderTitle}</Typography.Text>
        </AppName>

        <AppDescription>
          <Typography.Text ellipsis={true} style={{ width: '100%' }}>{renderDescription}</Typography.Text>
        </AppDescription>
      </AppInfo>

        <Flex justify='center' align='center' style={{ width: 32, height: 32, marginLeft: 3 }} onClick={(e) => e.stopPropagation()}>
          {
            downloading[index] ?
              <ProgressBar finish={!downloading} type="circle" size={30} /> :

              (
                !haveCardList?.includes(cardData?._id) ?
                  <Button type="text" icon={<PlusOutlined />} onClick={() => add(app, index)} /> :
                  <Button type="text" icon={<DeleteOutlined />} onClick={() => del(cardData?._id, index)} />
              )
          }
        </Flex>

    </AppItem>
  )
})

export default Card
