import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';


// 基于 antd 的 Progress组件封装一个进度条 如果 finish 字段为false 自己增加进度 但永远也不会完成 当传finish字段 为true 时直接跳转到完成的状态
const ProgressBar = ({ finish, interval = 10, ...props }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!finish) {
      const _ = setInterval(() => {
        setProgress(prev => {
          if (prev >= 99) {
            clearInterval(_);
            return 99;
          }
          return prev + 1;
        });
      }, interval);
      return () => clearInterval(_);
    } else {
      setProgress(100);
    }
  }, [finish]);

  return (
    <Progress status={finish ? 'success' : 'active'} {...props} percent={progress} />
  );
};

export default ProgressBar;