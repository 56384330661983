import { memo, forwardRef, useImperativeHandle } from 'react'
import { Input, Spin } from 'antd'
import { userImageCaptcha } from '@/api'
import { useRequest } from 'ahooks'
import { useRecoilState } from 'recoil'
import { imageCaptchaId as _imageCaptchaId } from '@/state'
import styled from 'styled-components'

const FormItemRender = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledInput = styled(Input)`
  flex: 1;
`

// 图形验证码-图片
const ImageWrapper = styled.div`
  cursor: pointer;
  width: 100px;
  height: 32px;
  margin-left: 5px;
  background-color: #eee;
  border-radius: 5px;
`
const ImageWrapperPlaceholder = styled.div`
  cursor: pointer;
  width: 100px;
  height: 32px;
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 12px;
  line-height: 29px;
  text-align: center;
`

// 图形验证码 + 输入框
const ImageCaptcha = forwardRef(({ ...props }, ref) => {
  const [, setImageCaptchaId] = useRecoilState(_imageCaptchaId) // 图片验证码
  const { data, run, loading } = useRequest(() =>
    userImageCaptcha().then((res) => {
      // 字典-字典取值类型
      setImageCaptchaId(res.data._id)
      return res.data
    }),
  )

  useImperativeHandle(ref, () => ({ run })) // 暴露run方法给父组件调用

  return (
    <FormItemRender>
      <StyledInput allowClear {...props} />

      <Spin spinning={loading}>

      {
        data?.code ? (
          <ImageWrapper
            onClick={run}
            dangerouslySetInnerHTML={{ __html: data.code }}
          />
        ) : (
          <ImageWrapperPlaceholder onClick={run}>
            点击获取验证码
          </ImageWrapperPlaceholder>
        )
      }
      </Spin>
    </FormItemRender>
  )
})

export default memo(ImageCaptcha)